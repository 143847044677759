import './style.css'

const CallWaiter:React.FC = () => {
    return <>
        <div className='callWaiter'>
            <button>
                Call Waiter
            </button>
        </div>
    </>
}
export default CallWaiter;