import './style.css'
const AddToCart:React.FC = () => {
    return <>
        <div className="addToCart">
            <button>
                Add to card
            </button>
        </div>
    </>
}
export default AddToCart